import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import logo from "../../images/footer-logo.png";

const Footer = () => {

    let navigate = useNavigate();

    return (
        <div className='footer'>
            <div className="row">
                <div className="col-md-5 col-12 row footer-element">
                    <div className="col-3">
                        <img className='img-fluid' src={logo}></img>
                    </div>
                    <div className="col-9">
                        <ul className='footer-link'>
                            <li>STS Swiss Tunelling Society</li>
                            <li>Via del Tiglio 2</li>
                            <li>C. P. 934</li>
                            <li>CH-6512 Bellinzona-Giubiasco</li>
                            <li><a className='email-link' href="mailto:sia-fgu@swisstunnel.ch">sia-fgu@swisstunnel.ch</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-7 col-12 row">
                    <div className="col-md-6 col-12 pb-3">
                        <ul className='footer-link'>
                            <li>Discover</li>
                            <li><a href="https://www.swisstunnel.ch/EN/The-STS-f4a86c00">The STS</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/Congresses-and-excursions-974c1200">Congresses and excursions</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/Members-49a89300">Members</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/STS-young-members-6f8fdb00">STS young members</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/Underground-construction-e86cb300">Underground construction</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/Training-e18ec600">Training</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-12 pb-3">
                        <ul className='footer-link'>
                            <li>Media</li>
                            <li><a href="https://www.swisstunnel.ch/EN/Photo-galleries-ab2bb600">Photo galleries</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/Newsletter-archive-ef2f7700">Newsletter archive</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/News-archive-571b2600">News archive</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/Agenda-archive-94f1a300">Agenda archive</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/EinBlick-in-den-Untergrund-388e4c00">50 years STS</a></li>
                            <li><a href="https://www.swisstunnel.ch/EN/Useful-links-b9b25d00">Useful links</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2 col-12 pb-3">
                        <ul className='footer-link'>
                            <li>Language</li>
                            <li className='footer-language' onClick={() => navigate(`/de/home`)}>Deutsch</li>
                            <li className='footer-language' onClick={() => navigate(`/fr/home`)}>Français</li>
                            <li className='footer-language' onClick={() => navigate(`/en/home`)}>English</li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 footer-end'>
                    <li className='me-3'>© STS Swiss Tunnelling Society</li>
                    <li className='me-3'><a href="https://www.swisstunnel.ch/EN/Impressum-1f046300">Impressum</a></li>
                    <li className='me-3'><a href="https://www.swisstunnel.ch/EN/Disclaimer-dfb58800">Disclaimer</a></li>
                    <li className='me-3'><a href="https://www.swisstunnel.ch/EN/Contacts-4d355200">Contacts</a></li>
                    <li className='me-3'><a href="https://www.swisstunnel.ch/EN/Credits-bf8b2400">Credits</a></li>
                </div>
            </div>
        </div>
    )
}

export default Footer