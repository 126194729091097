import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom/dist'
import { api, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets, getTicketTypesByEventId } from 'ticketino-api-client'
import parse from "html-react-parser";

import Header from './components/Header'
import Footer from './components/Footer'

const Participation = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [eventId] = useState(sessionStorage.getItem("eventId"));
    const [selectedCategory] = useState(sessionStorage.getItem("selectedCategory"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [agb, setAgb] = useState({
        accept: false,
        publication: true
    });

    const [ticketTypesAvailability, setTicketTypesAvailability] = useState([]);

    const [ticketTypesAdded, setTicketTypesAdded] = useState([]);
    const [tagungsBandsAdded, setTagungsBandsAdded] = useState(0);

    const [ticketAddress, setTicketAddress] = useState([]);
    const [ticketsToAdd, setTicketsToAdd] = useState([]);

    const [tagungsBands, setTagungsBands] = useState();
    const [addTagungsband, setAddTagungsband] = useState(false);

    const [promotioncode, setPromotioncode] = useState("");
    const [isPromotionAdded, setIsPromotionAdded] = useState(false);
    const [addedPromotion, setAddedPromotion] = useState("");

    const [isReductionPromotionAdded, setIsReductionPromotionAdded] = useState(false);
    const [addedReductionPromotion, setAddedReductionPromotion] = useState("");

    const [promotionError, setPromotionError] = useState("");

    // workshop ticketTypeIds
    const [settings, setSettings] = useState({
        stsTicketTypeId: 0,
        paketSTSymTicketTypeId: 0,
        congressTicketTypeId: 0,
        kolloquiumTicketTypeId: 0,
        dinnerTicketTypeId: 0,
        exkursionTicketTypeId: 0,
        tagungsbandTicketTypeId: 0,
        guestAndSponsorTicketTypeId: 0,
        exkursionSubTicketTypeId: 0,
        gotthardTicketTypeId: 0,
        entlastungsstollenTicketTypeId: 0,
        gubristtunnelTicketTypeId: 0,
        exkursionGotthardTicketTypeId: 0,
        exkursionEntlastungsstollenTicketTypeId: 0,
        exkursionGubristtunnelTicketTypeId: 0,
        isGotthardAvailable: true,
        isEntlastungsstollenAvailable: true,
        isGubristtunnelAvailable: true,
        stsymKolloquiumTicketTypeId: 0,
        stsymCongressTicketTypeId: 0,
        stsymDinnerTicketTypeId: 0,
        stsymExkursionTicketTypeId: 0
    })

    const [submissionError, setSubmissionError] = useState("");

    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]);

    const requestFormSettings = async () => {
        try {
            const response = await axios.get(`form/formsettings`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    // the dinner ticketType has to change depending on the added ticket
    useEffect(() => {

        if (!ticketsToAdd?.includes(settings?.paketSTSymTicketTypeId) && selectedCategory == "Paket STSym") {
            setSettings({
                ...settings,
                dinnerTicketTypeId: settings.stsymDinnerTicketTypeId,
                exkursionTicketTypeId: settings.stsymExkursionTicketTypeId,
                kolloquiumTicketTypeId: settings.stsymKolloquiumTicketTypeId
            });
        } else if (ticketsToAdd?.includes(settings?.paketSTSymTicketTypeId) && selectedCategory == "Paket STSym") {
            setSettings({
                ...settings,
                dinnerTicketTypeId: 733285,
                exkursionTicketTypeId: 733286,
                kolloquiumTicketTypeId: 733283
            });
        }
    }, [ticketsToAdd]);

    const loadOrder = async () => {
        try {
            // request form settings
            const updatedSettings = await requestFormSettings();

            const updatedOrder = await getOrderByOrderId(orderId);

            // TicketType
            let ticketTypes = await getTicketTypesByEventId(eventId);

            let updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    const ticketTypeName = info[0].name;

                    // only return ticketTypes which belong the the selected Category
                    if (ticketTypeName.startsWith(selectedCategory)) {
                        return { ...ticketType, info: ticketTypeInfo }
                    }
                })
            )

            updatedTicketTypes = updatedTicketTypes.filter((utt) => utt?.id > 0);

            let congressTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.congressTicketTypeIds.includes(utt.id))?.id;
            let kolloquiumTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.kolloquiumTicketTypeIds.includes(utt.id))?.id;
            let dinnerTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.dinnerTicketTypeIds.includes(utt.id))?.id;
            let exkursionTicketTypeId = updatedTicketTypes?.find(utt => updatedSettings.exkursionTicketTypeIds.includes(utt.id))?.id;
            let exkursionSubTicketTypeId = selectedCategory == "Student" ? updatedSettings.exkursionSubStudentTicketTypeId : updatedSettings.exkursionSubTicketTypeId;

            let ticketTypeIdsToAdd = updatedOrder.tickets.map((ticket) => { return ticket.ticketTypeId });
            let ticketAddressToAdd = updatedOrder.tickets.map((ticket) => { return { ticketTypeId: ticket.ticketTypeId, option1: ticket.option1 } });

            let congressTicket = ticketAddressToAdd.find(a => a.ticketTypeId == congressTicketTypeId && a.option1?.length > 0);

            let gotthardTicketTypeId = selectedCategory == "Student" ? updatedSettings.gotthardTicketTypeId20 : updatedSettings.gotthardTicketTypeId50;
            let entlastungsstollenTicketTypeId = selectedCategory == "Student" ? updatedSettings.entlastungsstollenTicketTypeId20 : updatedSettings.entlastungsstollenTicketTypeId50;
            let gubristtunnelTicketTypeId = selectedCategory == "Student" ? updatedSettings.gubristtunnelTicketTypeId20 : updatedSettings.gubristtunnelTicketTypeId50;

            if (congressTicket && (ticketTypeIdsToAdd.includes(exkursionSubTicketTypeId)
                || ticketTypeIdsToAdd.includes(gotthardTicketTypeId)
                || ticketTypeIdsToAdd.includes(entlastungsstollenTicketTypeId)
                || ticketTypeIdsToAdd.includes(gubristtunnelTicketTypeId))) {
                ticketAddressToAdd.find(a => a.ticketTypeId == congressTicketTypeId).option1 = "";
            }

            // filtering out the tagunsband ticketTypes
            ticketTypeIdsToAdd = ticketTypeIdsToAdd.filter(id => id !== updatedSettings.tagungsbandTicketTypeId);
            ticketAddressToAdd = ticketAddressToAdd.filter(address => address.ticketTypeId !== updatedSettings.tagungsbandTicketTypeId);

            let tagungsbands = 0;

            updatedOrder.tickets.map((ticket) => {
                if (ticket.ticketTypeId == updatedSettings.tagungsbandTicketTypeId) {
                    tagungsbands++;
                }
            })

            let ticketTypeIds = updatedTicketTypes.map(utt => utt.id);
            ticketTypeIds.push(updatedSettings.paketSTSymTicketTypeId, updatedSettings.tagungsbandTicketTypeId, updatedSettings.guestAndSponsorTicketTypeId, exkursionSubTicketTypeId)


            let updatedTicketTypesAvailability = await Promise.all(ticketTypeIds.map(async (id) => {
                const availability = await getTicketTypeNumberOfAvailableTickets(id, orderId);
                return { ticketTypeId: id, availability: { ...availability } }
            }))

            let maxTicketsGotthard = updatedSettings.maxTicketsGotthard;
            let maxTicketsSihl = updatedSettings.maxTicketsSihl;
            let maxTicketsGubrist = updatedSettings.maxTicketsGubrist;

            let totalTicketsSoldGotthard = 0;
            let totalTicketsSoldEntlastungsstollen = 0;
            let totalTicketsSoldGubristtunnel = 0;

            let ticketTypesGotthard = [
                updatedSettings.gotthardTicketTypeIdFree,
                updatedSettings.gotthardTicketTypeId20,
                updatedSettings.gotthardTicketTypeId50
            ]

            let ticketTypesEntlastungsstollen = [
                updatedSettings.entlastungsstollenTicketTypeIdFree,
                updatedSettings.entlastungsstollenTicketTypeId20,
                updatedSettings.entlastungsstollenTicketTypeId50
            ];

            let ticketTypesGubristtunnel = [
                updatedSettings.gubristtunnelTicketTypeIdFree,
                updatedSettings.gubristtunnelTicketTypeId20,
                updatedSettings.gubristtunnelTicketTypeId50
            ];

            await Promise.all(
                ticketTypesGotthard.map(async (ticketTypeId) => {
                    var ticketsSold = await getNumberOfTicketsSoldOfTicketType(ticketTypeId) ?? 0;
                    totalTicketsSoldGotthard = totalTicketsSoldGotthard + ticketsSold;
                })
            )

            await Promise.all(
                ticketTypesEntlastungsstollen.map(async (ticketTypeId) => {
                    var ticketsSold = await getNumberOfTicketsSoldOfTicketType(ticketTypeId) ?? 0;
                    totalTicketsSoldEntlastungsstollen = totalTicketsSoldEntlastungsstollen + ticketsSold;
                })
            )

            await Promise.all(
                ticketTypesGubristtunnel.map(async (ticketTypeId) => {
                    var ticketsSold = await getNumberOfTicketsSoldOfTicketType(ticketTypeId) ?? 0;
                    totalTicketsSoldGubristtunnel = totalTicketsSoldGubristtunnel + ticketsSold;
                })
            )

            setSettings({
                stsTicketTypeId: updatedSettings.stsTicketTypeId,
                paketSTSymTicketTypeId: updatedSettings.paketSTSymTicketTypeId,
                congressTicketTypeId: congressTicketTypeId,
                kolloquiumTicketTypeId: kolloquiumTicketTypeId,
                dinnerTicketTypeId: dinnerTicketTypeId,
                exkursionTicketTypeId: exkursionTicketTypeId,
                tagungsbandTicketTypeId: updatedSettings.tagungsbandTicketTypeId,
                guestAndSponsorTicketTypeId: updatedSettings.guestAndSponsorTicketTypeId,
                exkursionSubTicketTypeId: exkursionSubTicketTypeId,
                gotthardTicketTypeId: gotthardTicketTypeId,
                entlastungsstollenTicketTypeId: entlastungsstollenTicketTypeId,
                gubristtunnelTicketTypeId: gubristtunnelTicketTypeId,
                exkursionGotthardTicketTypeId: updatedSettings.gotthardTicketTypeIdFree,
                exkursionEntlastungsstollenTicketTypeId: updatedSettings.entlastungsstollenTicketTypeIdFree,
                exkursionGubristtunnelTicketTypeId: updatedSettings.gubristtunnelTicketTypeIdFree,
                isGotthardAvailable: !(totalTicketsSoldGotthard >= maxTicketsGotthard),
                isEntlastungsstollenAvailable: !(totalTicketsSoldEntlastungsstollen >= maxTicketsSihl),
                isGubristtunnelAvailable: !(totalTicketsSoldGubristtunnel >= maxTicketsGubrist),
                stsymKolloquiumTicketTypeId: updatedSettings.stsymKolloquiumTicketTypeId,
                stsymCongressTicketTypeId: updatedSettings.stsymCongressTicketTypeId,
                stsymDinnerTicketTypeId: updatedSettings.stsymDinnerTicketTypeId,
                stsymExkursionTicketTypeId: updatedSettings.stsymExkursionTicketTypeId
            });

            setTicketTypesAdded(ticketTypeIdsToAdd);
            setTicketsToAdd(ticketTypeIdsToAdd);
            setTicketAddress(ticketAddressToAdd);

            setTagungsBands(tagungsbands == 0 ? null : tagungsbands);
            setTagungsBandsAdded(tagungsbands);

            if (tagungsbands > 0) {
                setAddTagungsband(true);
            }

            setTicketTypesAvailability(updatedTicketTypesAvailability);
        } catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const applyPromotionCodeToOrder = async (orderId, code) => {
        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources?.ErrorPromotionCode);
        }
    }

    const getNumberOfTicketsSoldOfTicketType = async (ticketTypeId) => {
        try {
            const res = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/TicketsSold`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const addPromotioncode = async () => {
        const order = await applyPromotionCodeToOrder(orderId, promotioncode);

        if (order?.unlockedTicketTypes?.includes(settings.guestAndSponsorTicketTypeId)) {
            setIsPromotionAdded(true);
            setPromotionError("");
            setAddedPromotion(promotioncode);
        } else if (order.id > 0) {
            setIsReductionPromotionAdded(true);
            setPromotionError("");
            setAddedReductionPromotion(promotioncode);
            await removePromotionCode(promotioncode);
        }
    }

    const removePromotionCode = async (promotion) => {
        try {
            const response = axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const addTicketsToBasket = async (addTicketTypes) => {
        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { ticketsToAdd: addTicketTypes });
            return response.data;
        } catch (error) {
            setSubmissionError(error.response.data);
            console.error(error);
        }
    }

    const deleteTicketsFromBasket = async (ticketIds) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: { "TicketsToRemove": ticketIds } });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const updateAddressOfTicket = async (ticketId, body) => {
        try {
            const response = await axios.put(`${baseUrl}/Ticket/${ticketId}/Address`, body);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const workshopTicketMapping = {
        "Paket STSym": settings.paketSTSymTicketTypeId,
        "Kolloquium": settings.kolloquiumTicketTypeId,
        "Congress": settings.congressTicketTypeId,
        "Dinner": settings.dinnerTicketTypeId,
        "Exkursion": settings.exkursionTicketTypeId,
        "Tagungsband": settings.tagungsbandTicketTypeId,
        "ExkursionSub": settings.exkursionSubTicketTypeId,
        "GuestAndSponsor": settings.guestAndSponsorTicketTypeId,
        "Gotthard": settings.gotthardTicketTypeId,
        "Entlastung": settings.entlastungsstollenTicketTypeId,
        "Gubrist": settings.gubristtunnelTicketTypeId,
        "GotthardExkursion": settings.exkursionGotthardTicketTypeId,
        "EntlastungExkursion": settings.exkursionEntlastungsstollenTicketTypeId,
        "GubristExkursion": settings.exkursionGubristtunnelTicketTypeId,
    };

    const onCheckboxChange = (workshop) => {
        const ticketTypeId = workshopTicketMapping[workshop];

        // hard coding setting for paket stsym ticket type
        if (ticketTypeId == settings.paketSTSymTicketTypeId) {
            if (ticketsToAdd.includes(settings.paketSTSymTicketTypeId)) {
                setTicketsToAdd((tickets) => {
                    return tickets.filter((t) => t == settings.stsTicketTypeId || t == settings.paketSTSymTicketTypeId);
                })

                setTicketAddress([]);

                setAddTagungsband(0);
                setTagungsBands();
            } else {
                if (ticketsToAdd.includes(settings.exkursionTicketTypeId) || ticketsToAdd.includes(settings.exkursionSubTicketTypeId)) {
                    setTicketsToAdd((tickets) => {
                        return tickets.filter((t) => t != settings.exkursionTicketTypeId && t != settings.exkursionSubTicketTypeId);
                    })

                    setTicketAddress((address) => {
                        return address.filter((a) => a.ticketTypeId != settings.exkursionTicketTypeId || a.ticketTypeId != settings.exkursionSubTicketTypeId);
                    })
                }
            }
        }

        // hard coding settings for congrss ticket type
        if (ticketTypeId == settings.congressTicketTypeId) {
            if (!ticketsToAdd.includes(settings.congressTicketTypeId)) {
                setTicketAddress((address) => [...address, { ticketTypeId: ticketTypeId, option1: resources?.Workshop4Sub1 }]);
            } else {
                setTicketsToAdd((tickets) => {
                    return tickets.filter((t) => t !== settings.exkursionSubTicketTypeId
                        && t !== settings.gotthardTicketTypeId
                        && t !== settings.entlastungsstollenTicketTypeId
                        && t !== settings.gubristtunnelTicketTypeId);
                })

                setTicketAddress((address) => {
                    if (address?.findIndex(a => a.ticketTypeId == settings.exkursionSubTicketTypeId) > -1) {
                        return address?.filter((a) => a?.ticketTypeId !== settings.exkursionSubTicketTypeId);
                    }

                    if (address?.findIndex(a => a.ticketTypeId == ticketTypeId) > -1) {
                        return address?.filter((a) => a?.ticketTypeId !== ticketTypeId);
                    }

                    return address;
                })
            }
        }

        // hard codding settings for the exkursion ticket type
        if (ticketTypeId == settings.exkursionTicketTypeId) {
            if (!ticketsToAdd.includes(settings.exkursionTicketTypeId)) {
            } else {
                setTicketsToAdd((tickets) => {
                    return tickets.filter((t) => t !== settings.exkursionSubTicketTypeId
                        && t !== settings.gotthardTicketTypeId
                        && t !== settings.entlastungsstollenTicketTypeId
                        && t !== settings.gubristtunnelTicketTypeId
                        && t !== settings.exkursionGotthardTicketTypeId
                        && t !== settings.exkursionEntlastungsstollenTicketTypeId
                        && t !== settings.exkursionGubristtunnelTicketTypeId);
                })

                setTicketAddress((address) => {
                    if (address?.findIndex(a => a.ticketTypeId == settings.exkursionSubTicketTypeId) > -1) {
                        return address?.filter((a) => a?.ticketTypeId !== settings.exkursionSubTicketTypeId);
                    }
                    return address;
                })
            }
        }

        // hard coding for the exkursion sub ticket type
        if (ticketTypeId == settings.gotthardTicketTypeId
            || ticketTypeId == settings.gubristtunnelTicketTypeId
            || ticketTypeId == settings.entlastungsstollenTicketTypeId
            || ticketTypeId == settings.exkursionGotthardTicketTypeId
            || ticketTypeId == settings.exkursionEntlastungsstollenTicketTypeId
            || ticketTypeId == settings.exkursionGubristtunnelTicketTypeId) {

            if (ticketsToAdd.includes(settings.congressTicketTypeId)) {
                setTicketAddress((address) => {
                    if (address?.findIndex(a => a.ticketTypeId == settings.congressTicketTypeId) > -1) {
                        return address?.filter((a) => a?.ticketTypeId !== settings.congressTicketTypeId);
                    }
                    return address;
                })
            }

            if (ticketsToAdd.includes(settings.gotthardTicketTypeId)
                || ticketsToAdd.includes(settings.entlastungsstollenTicketTypeId)
                || ticketsToAdd.includes(settings.gubristtunnelTicketTypeId)
                || ticketsToAdd.includes(settings.exkursionGotthardTicketTypeId)
                || ticketsToAdd.includes(settings.exkursionEntlastungsstollenTicketTypeId)
                || ticketsToAdd.includes(settings.exkursionGubristtunnelTicketTypeId)) {
                setTicketsToAdd((tickets) => {
                    return tickets.filter((t) => t !== settings.exkursionSubTicketTypeId
                        && t !== settings.gotthardTicketTypeId
                        && t !== settings.entlastungsstollenTicketTypeId
                        && t !== settings.gubristtunnelTicketTypeId
                        && t !== settings.exkursionGotthardTicketTypeId
                        && t !== settings.exkursionEntlastungsstollenTicketTypeId
                        && t !== settings.exkursionGubristtunnelTicketTypeId);
                })
            }
        }

        if (ticketTypeId) {
            setTicketsToAdd((tickets) => {
                if (tickets.includes(ticketTypeId)) {
                    return tickets.filter((t) => t !== ticketTypeId);
                } else {
                    return [...tickets, ticketTypeId];
                }
            });
        }
    }

    const onTicketAddressChange = (workshop, value, parentTicketTypeId) => {
        const ticketTypeId = workshopTicketMapping[workshop];

        // hard coding settings for congrss ticket type
        if (parentTicketTypeId) {

            if (!ticketsToAdd.includes(parentTicketTypeId)) {
                setTicketsToAdd([...ticketsToAdd, parentTicketTypeId])
            }

            if (ticketTypeId == settings.congressTicketTypeId) {
                setTicketsToAdd((tickets) => {
                    if (tickets.includes(settings.exkursionSubTicketTypeId)) {
                        return tickets.filter((t) => t !== settings.exkursionSubTicketTypeId);
                    }

                    return tickets;
                })

                setTicketAddress((address) => {
                    if (address?.findIndex(a => a.ticketTypeId == settings.exkursionSubTicketTypeId) > -1) {
                        return address?.filter((a) => a?.ticketTypeId !== settings.exkursionSubTicketTypeId);
                    }

                    return address;
                })

                setTicketsToAdd((tickets) => {
                    return tickets.filter((t) => t !== settings.exkursionSubTicketTypeId
                        && t !== settings.gotthardTicketTypeId
                        && t !== settings.entlastungsstollenTicketTypeId
                        && t !== settings.gubristtunnelTicketTypeId);
                })
            } else {
                setTicketAddress((address) => {
                    if (address?.findIndex(a => a.ticketTypeId == settings.congressTicketTypeId) > -1) {
                        return address?.filter((a) => a?.ticketTypeId !== settings.congressTicketTypeId);
                    }

                    return address;
                })
            }
        }

        if (ticketTypeId) {
            setTicketAddress((address) => {
                const addressIndex = address.findIndex((a) => a.ticketTypeId === ticketTypeId);

                if (addressIndex !== -1) {
                    const updatedAddress = [...address];
                    updatedAddress[addressIndex] = { ...address[addressIndex], option1: value };
                    return updatedAddress;
                } else {
                    return [...address, { ticketTypeId, option1: value }];
                }
            });
        }

        if (!(ticketsToAdd.includes(ticketTypeId)) && !(parentTicketTypeId == ticketTypeId)) {
            setTicketsToAdd((tickets) => {
                return [...tickets, ticketTypeId];
            });
        }
    };

    const onSubmit = async () => {
        const validation = validate();

        const sortOrder = [
            settings.paketSTSymTicketTypeId,
            settings.kolloquiumTicketTypeId,
            settings.guestAndSponsorTicketTypeId,
            settings.congressTicketTypeId,
            settings.gotthardTicketTypeId,
            settings.entlastungsstollenTicketTypeId,
            settings.gubristtunnelTicketTypeId,
            settings.exkursionTicketTypeId,
            settings.exkursionSubTicketTypeId,
            settings.exkursionGotthardTicketTypeId,
            settings.exkursionEntlastungsstollenTicketTypeId,
            settings.exkursionGubristtunnelTicketTypeId,
            settings.dinnerTicketTypeId,
            settings.tagungsbandTicketTypeId
        ];

        if (!validation) {
            return;
        }

        let addTicketTypes = [];
        let addTagungsbandTicketTypes = [];
        let deleteTagungsbandTicketIdsToDelete = [];

        addTicketTypes = ticketsToAdd.map((ticketTypeId) => {
            return { ticketTypeId: ticketTypeId, quantity: 1 }
        })

        addTicketTypes = addTicketTypes.sort((a, b) => {
            const indexA = sortOrder.indexOf(a.ticketTypeId);
            const indexB = sortOrder.indexOf(b.ticketTypeId);

            return indexA - indexB
        })

        if (isPromotionAdded && ticketsToAdd.includes(settings.guestAndSponsorTicketTypeId)) {
            addTicketTypes.find(att => att.ticketTypeId == settings.guestAndSponsorTicketTypeId).promotionCode = addedPromotion;
        }

        // prevent adding ticketTypes which have already been added
        addTicketTypes = addTicketTypes.filter((att) => !ticketTypesAdded.includes(att.ticketTypeId));

        // remove ticketTypes which have been changed
        let deleteTicketTypes = ticketTypesAdded.filter((tta) => !ticketsToAdd.includes(tta));

        const order = await addTicketsToBasket(addTicketTypes);

        await Promise.all(order.tickets.map(async (ticket) => {
            let address = ticketAddress.find((ta) => ta.ticketTypeId == ticket.ticketTypeId);

            if (address?.option1) {
                await updateAddressOfTicket(ticket.id, { option1: address.option1 });
            }
        }))

        if (agb.publication) {
            sessionStorage.setItem("agbPublication", true);
        }

        if (deleteTicketTypes.length > 0) {
            let ticketIdsToDelete = order.tickets.map(ticket => {
                if (deleteTicketTypes.includes(ticket.ticketTypeId)) {
                    return ticket.id
                }
            }).filter(t => t > 0)

            await deleteTicketsFromBasket(ticketIdsToDelete);
        }

        // configuring tagungausband
        let amountTagungsBands = parseInt(tagungsBands ?? 0) - parseInt(tagungsBandsAdded ?? 0);

        if (amountTagungsBands > 0) {
            addTagungsbandTicketTypes.push({ ticketTypeId: settings.tagungsbandTicketTypeId, quantity: amountTagungsBands })
        }

        if (addTagungsbandTicketTypes.length > 0) {
            await addTicketsToBasket(addTagungsbandTicketTypes);
        }

        order.tickets.forEach((ticket) => {
            if (ticket.ticketTypeId == settings.tagungsbandTicketTypeId) {
                if (amountTagungsBands < 0) {
                    deleteTagungsbandTicketIdsToDelete.push(ticket.id)
                    amountTagungsBands++;
                }
            }
        })

        if (deleteTagungsbandTicketIdsToDelete.length > 0) {
            await deleteTicketsFromBasket(deleteTagungsbandTicketIdsToDelete);
        }

        const updatedOrder = await getOrderByOrderId(orderId);

        const dinnerTickets = updatedOrder?.tickets?.filter(t => t.ticketTypeId == 733285 || t.ticketTypeId == settings.stsymDinnerTicketTypeId);
        const exkursionTickets = updatedOrder?.tickets?.filter(t => t.ticketTypeId == 733286 || t.ticketTypeId == settings.stsymExkursionTicketTypeId);
        const kolloquiumTickets = updatedOrder?.tickets?.filter(t => t.ticketTypeId == 733283 || t.ticketTypeId == settings.stsymKolloquiumTicketTypeId);

        const paketSTSymTicket = updatedOrder?.tickets?.find(t => t.ticketTypeId == 733282);

        if (dinnerTickets.length > 1) {
            if (paketSTSymTicket) {
                await deleteTicketsFromBasket([dinnerTickets.find(t => t.price > 0)?.id]);
            } else {
                await deleteTicketsFromBasket([dinnerTickets.find(t => t.price == 0)?.id]);
            }
        }

        if (kolloquiumTickets.length > 1) {
            if (paketSTSymTicket) {
                await deleteTicketsFromBasket([kolloquiumTickets.find(t => t.price > 0)?.id]);
            } else {
                await deleteTicketsFromBasket([kolloquiumTickets.find(t => t.price == 0)?.id]);
            }
        }

        if (exkursionTickets.length > 0) {
            if (paketSTSymTicket) {
                await deleteTicketsFromBasket([exkursionTickets.find(t => t.price > 60)?.id]);
            } else {
                await deleteTicketsFromBasket([exkursionTickets.find(t => t.price < 60)?.id]);
            }
        }



        if (isReductionPromotionAdded && addedReductionPromotion != "") {
            await applyPromotionCodeToOrder(orderId, addedReductionPromotion);
            sessionStorage.setItem("addedPromotion", addedReductionPromotion);
        }

        navigate(`/${language}/checkout`);
    }

    const validateSubmission = () => {

        let validation = true;

        if (ticketsToAdd.includes(settings.paketSTSymTicketTypeId)) {
            if (!ticketsToAdd.includes(settings.congressTicketTypeId)) {
                validation = false;
            }
        }

        if (ticketsToAdd.includes(settings.exkursionTicketTypeId)) {
            if (!(ticketsToAdd.includes(settings.exkursionGotthardTicketTypeId)
                || ticketsToAdd.includes(settings.exkursionEntlastungsstollenTicketTypeId)
                || ticketsToAdd.includes(settings.exkursionGubristtunnelTicketTypeId))) {
                validation = false;
            }
        }

        if (ticketsToAdd.length <= 0 && tagungsBands <= 0) {
            validation = false;
        }

        if (addTagungsband && !(tagungsBands > 0)) {
            validation = false;
        }

        if (agb.accept != true) {
            validation = false;
        }

        return validation;
    }

    const validate = () => {

        let tagungsbandAvailability = ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.tagungsbandTicketTypeId)?.availability?.availableTickets;

        if (tagungsBands > tagungsbandAvailability) {
            setSubmissionError(`Leider stehen nicht ausreichend Tagungsbände zur Verfügung. Bitte wählen Sie eine geringere Anzahl. Derzeit sind nur ${tagungsbandAvailability} Tagungsbände verfügbar.`);
            return false;
        }

        return true;
    }

    let validation = validateSubmission();
    let disabled = !validation;

    return (
        <div className='container page-container'>
            <Header />
            <div className='mt-3'>
                <div className='text-center'>
                    <p className='fs-4 mb-4 text-primary fw-semibold'>
                        <span className='underline'>{resources?.ParticipationTitle}</span>
                    </p>
                </div>
                <div className='mb-4'>
                    <p>{resources?.PromotionCodeDesc} <Link to={languageId == 2 ? "/files/STS_Instructions pour l'utilisation des codes promo_FR.pdf" : languageId == 3 ? "/files/STS_How to redeem promocodes_EN.pdf" : "/files/STS_Anleitung_Promocodes.pdf"} target="_blank">{resources?.PromotionCodeDesc1}</Link>).</p>
                    <div className="row">
                        <div className="col-md-9 col-7 mb-2">
                            <input className='form-control' placeholder={resources?.PromotionCode} value={promotioncode} onChange={(e) => setPromotioncode(e.target.value)} />
                        </div>
                        <div className='col-md-3 col-5 text-end'>
                            <button className='btn button' onClick={addPromotioncode}>{resources?.Redeem}</button>
                        </div>
                        {promotionError != "" && <div className="col-12">
                            <p className='text-danger fs-6 mb-0'>{promotionError}</p>
                        </div>}
                        {((isPromotionAdded || isReductionPromotionAdded) && promotionError == "") && <div className="col-12">
                            <p className='text-success fs-6 mb-0'>{resources?.SuccessPromotionCode}</p>
                        </div>}
                    </div>
                </div>
                <div className='workshops mb-5'>
                    {selectedCategory == "Paket STSym" && <div className={`category row ${ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.paketSTSymTicketTypeId)?.availability?.availableTickets <= 0 ? "category-hide" : ""}`}>
                        <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Paket STSym")}><i className={`bi bi${ticketsToAdd.includes(settings.paketSTSymTicketTypeId) ? "-check" : ""}-square`}></i></div>
                        <div className="col-10">
                            {resources?.Workshop1}
                        </div>
                    </div>}
                    <div className={`category row ${ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.kolloquiumTicketTypeId)?.availability?.availableTickets <= 0 ? "category-hide" : ""}`}>
                        <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Kolloquium")}><i className={`bi bi${ticketsToAdd.includes(settings.kolloquiumTicketTypeId) ? "-check" : ""}-square`}></i></div>
                        <div className="col-10">
                            {resources?.Workshop2}
                        </div>
                    </div>
                    {(isPromotionAdded || ticketsToAdd.includes(settings.guestAndSponsorTicketTypeId)) && <div className={`category row promotion-category`}>
                        <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("GuestAndSponsor")}><i className={`bi bi${ticketsToAdd.includes(settings.guestAndSponsorTicketTypeId) ? "-check" : ""}-square`}></i></div>
                        <div className="col-10">
                            {resources?.Workshop3}
                        </div>
                    </div>}
                    <div className={`category row ${((!ticketsToAdd.includes(settings.paketSTSymTicketTypeId) && selectedCategory == "Paket STSym") || ticketsToAdd.includes(settings.exkursionTicketTypeId) || ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.congressTicketTypeId)?.availability?.availableTickets <= 0) ? "category-hide" : ""}`}>
                        <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Congress")}><i className={`bi bi${ticketsToAdd.includes(settings.congressTicketTypeId) ? "-check" : ""}-square`}></i></div>
                        <div className="col-10">
                            {resources?.Workshop4}
                            {(!ticketsToAdd.includes(settings.exkursionTicketTypeId) && ticketsToAdd.includes(settings.congressTicketTypeId)) && <>
                                <div className="row mt-2">
                                    <div className="col-2 fs-5 text-secondary" onClick={() => onTicketAddressChange("Congress", resources?.Workshop4Sub1, settings.congressTicketTypeId)}><i className={`bi bi${ticketAddress.find(ta => ta?.ticketTypeId == settings.congressTicketTypeId)?.option1 == resources?.Workshop4Sub1 ? "-check" : ""}-square`}></i></div>
                                    <div className="col-10">{resources?.Workshop4Sub1}</div>
                                </div>
                                <div className={"row mt-2 " + (!settings.isGotthardAvailable ? "category-hide" : "")}>
                                    <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Gotthard")}><i className={`bi bi${ticketsToAdd.includes(settings.gotthardTicketTypeId) ? "-check" : ""}-square`}></i></div>
                                    <div className="col-10">{resources?.Workshop4Sub2}</div>
                                </div>
                                <div className={"row mt-2 " + (!settings.isEntlastungsstollenAvailable ? "category-hide" : "")}>
                                    <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Entlastung")}><i className={`bi bi${ticketsToAdd.includes(settings.entlastungsstollenTicketTypeId) ? "-check" : ""}-square`}></i></div>
                                    <div className="col-10">{resources?.Workshop4Sub3}</div>
                                </div>
                                <div className={"row mt-2 " + (!settings.isGubristtunnelAvailable ? "category-hide" : "")}>
                                    <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Gubrist")}><i className={`bi bi${ticketsToAdd.includes(settings.gubristtunnelTicketTypeId) ? "-check" : ""}-square`}></i></div>
                                    <div className="col-10">{resources?.Workshop4Sub4}</div>
                                </div>
                            </>}
                        </div>
                    </div>
                    <div className={`category row ${ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.dinnerTicketTypeId)?.availability?.availableTickets <= 0 ? "category-hide" : ""}`}>
                        <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Dinner")}><i className={`bi bi${ticketsToAdd.includes(settings.dinnerTicketTypeId) ? "-check" : ""}-square`}></i></div>
                        <div className="col-10">
                            {resources?.Workshop5}
                        </div>
                    </div>
                    <div className={`category row ${(ticketsToAdd.includes(settings.paketSTSymTicketTypeId) || ticketsToAdd.includes(settings.congressTicketTypeId) || ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.exkursionTicketTypeId)?.availability?.availableTickets <= 0) ? "category-hide" : ""}`}>
                        <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("Exkursion")}><i className={`bi bi${ticketsToAdd.includes(settings.exkursionTicketTypeId) ? "-check" : ""}-square`}></i></div>
                        <div className="col-10">
                            {resources?.Workshop6}
                            {(!ticketsToAdd.includes(settings.congressTicketTypeId) && ticketsToAdd.includes(settings.exkursionTicketTypeId)) && <>
                                <div className={"row mt-2 " + (!settings.isGotthardAvailable ? "category-hide" : "")}>
                                    <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("GotthardExkursion")}><i className={`bi bi${ticketsToAdd.includes(settings.exkursionGotthardTicketTypeId) ? "-check" : ""}-square`}></i></div>
                                    <div className="col-10">{resources?.Workshop6Sub1}</div>
                                </div>
                                <div className={"row mt-2 " + (!settings.isEntlastungsstollenAvailable ? "category-hide" : "")}>
                                    <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("EntlastungExkursion")}><i className={`bi bi${ticketsToAdd.includes(settings.exkursionEntlastungsstollenTicketTypeId) ? "-check" : ""}-square`}></i></div>
                                    <div className="col-10">{resources?.Workshop6Sub2}</div>
                                </div>
                                <div className={"row mt-2 " + (!settings.isGubristtunnelAvailable ? "category-hide" : "")}>
                                    <div className="col-2 fs-5 text-secondary" onClick={() => onCheckboxChange("GubristExkursion")}><i className={`bi bi${ticketsToAdd.includes(settings.exkursionGubristtunnelTicketTypeId) ? "-check" : ""}-square`}></i></div>
                                    <div className="col-10">{resources?.Workshop6Sub3}</div>
                                </div>
                            </>}
                        </div>
                    </div>
                    <div className={`category row ${(ticketTypesAvailability?.find((tta) => tta?.ticketTypeId == settings.tagungsbandTicketTypeId)?.availability?.availableTickets <= 0 && tagungsBands <= 0) ? "category-hide" : ""}`}>
                        <div
                            className="col-2 fs-5 text-secondary"
                            onClick={() => {
                                if (addTagungsband) {
                                    setAddTagungsband(false);
                                    setTagungsBands();
                                } else {
                                    setAddTagungsband(true);
                                }
                            }}>
                            <i className={`bi bi${addTagungsband ? "-check" : ""}-square`}></i>
                        </div>
                        <div className="col-10">
                            {resources?.Workshop7}
                            {addTagungsband ? <div className="row mt-2">
                                <div className="col-12 row">
                                    <div className='col-md-5 col-12'>
                                        {resources?.Workshop7Sub1}
                                    </div>
                                    <div className='col-md-5 col-12'>
                                        <input className="form-control" type="number" placeholder={resources?.TagungsbandDesc}
                                            value={tagungsBands}
                                            min={0}
                                            step={1}
                                            onChange={(e) => setTagungsBands(e.target.value)}
                                            disabled={!addTagungsband} />
                                    </div>
                                </div>
                            </div> : <></>}
                        </div>
                    </div>
                    <div className='category row'>
                        <div className="col-12">
                            <div className='row'>
                                <div className="col-2"></div>
                                <div className="col">
                                    <p>{resources?.AGB2}</p>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-2 fs-5 text-secondary" onClick={() => setAgb({ ...agb, publication: !agb.publication })}><i className={`bi bi-${agb.publication ? "check-" : ""}circle`}></i></div>
                                <div className="col-10">{resources?.Yes}</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-2 fs-5 text-secondary" onClick={() => setAgb({ ...agb, publication: !agb.publication })}><i className={`bi bi-${agb.publication ? "" : "check-"}circle`}></i></div>
                                <div className="col-10">{resources?.No}</div>
                            </div>
                        </div>
                    </div>
                    <div className='category row'>
                        <div className="col-2 fs-5 text-secondary" onClick={() => setAgb({ ...agb, accept: !agb.accept })}><i className={`bi bi-${agb.accept ? "check-" : ""}circle`}></i></div>
                        <div className="col-10">{resources?.AGB1} <Link to={languageId == 2 ? "/files/STS_AGB_FR.pdf" : languageId == 3 ? "/files/STS_AGB_EN.pdf" : "/files/STS_AGB.pdf"} target="_blank">{resources?.AGB01}</Link> {resources?.AGB11}</div>
                    </div>
                </div>
                <div className='row'>
                    {submissionError != "" && <div className="col-12">
                        <p className='text text-danger mt-0'>{submissionError}</p>
                    </div>}
                    <div className="col-6">
                        <button className='btn button text-start' onClick={() => navigate(-1)}>{resources?.Back}</button>
                    </div>
                    <div className="col-6 text-end">
                        <button className='btn button' disabled={disabled} onClick={onSubmit}>{resources?.Next}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Participation