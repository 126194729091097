import React from 'react'

import flyer from "../../images/logo.png";
import banner from "../../images/banner.png";
import { useNavigate, useParams } from 'react-router-dom';

const Header = (props) => {

    return (
        <div>
            <div className='row mb-4'>
                <div className='col-12 fill'>
                    <img className='img-fluid' src={banner} alt="sts banner"></img>
                </div>
            </div>
            <div>
                <img className='text-center flyer' src={flyer} alt="sts logo"></img>
            </div>
        </div>
    )
}

export default Header