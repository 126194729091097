import React, { useEffect, useState } from 'react'

import Header from './components/Header'
import Footer from './components/Footer'
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Confirmation = () => {

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div className='container page-container'>
            <Header />
            <div className='mt-3'>
                <div className='text-center w-50 container'>
                    <p className='fs-4 mb-4 text-primary fw-semibold'>
                        <span className='underline'>{resources?.Confirmation}</span>
                    </p>
                    <p className='mb-4'>{resources?.Confirmation1}</p>
                    <p className='mb-4'>{resources?.Confirmation2}</p>
                    <p className='fw-bold mb-0'>{resources?.Confirmation3}</p>
                    <p className='mb-5'>{resources?.Confirmation4}</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Confirmation