import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom/dist'

import Header from './components/Header'
import Footer from './components/Footer'
import axios from 'axios';
import { api, getAddressOfOrder, getTicketTypeInfosByTicketTypeId } from 'ticketino-api-client';

const Checkout = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [selectedCategory] = useState(sessionStorage.getItem("selectedCategory"));
    const [selectedPackage, setSelectedPackage] = useState("");

    const [address, setAddress] = useState({
        firstName: "",
        name: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        option5: "",
        option8: "",
    });

    const [tagungsbands, setTagungsbands] = useState(0);

    const [settings, setSettings] = useState({
        stsTicketTypeId: 0,
        paketSTSymTicketTypeId: 0,
        congressTicketTypeIds: [],
        kolloquiumTicketTypeIds: [],
        dinnerTicketTypeIds: [],
        exkursionTicketTypeIds: [],
        tagungsbandTicketTypeId: 0,
        guestAndSponsorTicketTypeId: 0,
        exkursionSubTicketTypeId: 0,
        gotthardTicketTypeId: 0,
        entlastungsstollenTicketTypeId: 0,
        gubristtunnelTicketTypeId: 0,
        exkursionGotthardTicketTypeId: 0,
        exkursionEntlastungsstollenTicketTypeId: 0,
        exkursionGubristtunnelTicketTypeId: 0,
        stsymKolloquiumTicketTypeId: 0,
        stsymCongressTicketTypeId: 0,
        stsymDinnerTicketTypeId: 0,
        stsymExkursionTicketTypeId: 0
    })

    const [order, setOrder] = useState({});
    const [payment, setPayment] = useState({
        paymentMethodId: 0,
        deliveryMethodId: 0
    });

    const [ticketTypeInfo, setTicketTypeInfo] = useState([]);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    const paymentTypes = [
        {
            id: 1,
            name: resources?.MasterCard,
            description: resources?.MasterCardDesc
        },
        {
            id: 7,
            name: resources?.Visa,
            description: resources?.VisaDesc
        },
        {
            id: 8,
            name: resources?.AmericanExpress,
            description: resources?.AmericanExpressDesc
        },
        {
            id: 14,
            name: resources?.Twint,
            description: resources?.TwintDesc
        },
        {
            id: 10,
            name: resources?.PayPal,
            description: resources?.PayPalDesc
        },
        {
            id: 9,
            name: resources?.PostFinance,
            description: resources?.PostFinanceDesc
        }
    ];

    const deliveryTypes = [
        {
            id: 1,
            name: resources?.PrintAtHome,
            description: resources?.PrintAtHomeDesc
        }
    ];

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]);

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestFormSettings = async () => {
        try {
            const response = await axios.get(`form/formsettings`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const loadOrder = async () => {
        try {

            const updatedOrder = await getOrderByOrderId(orderId);
            let addressOfOrder = await getAddressOfOrder(orderId);

            if (updatedOrder.paymentMethodId == 2) {
                changePaymentType(1)
            }

            // request form settings
            const updatedSettings = await requestFormSettings();
            let exkursionSubTicketTypeId = selectedCategory == "Student" ? updatedSettings.exkursionSubStudentTicketTypeId : updatedSettings.exkursionSubTicketTypeId;
            let gotthardTicketTypeId = selectedCategory == "Student" ? updatedSettings.gotthardTicketTypeId20 : updatedSettings.gotthardTicketTypeId50;
            let entlastungsstollenTicketTypeId = selectedCategory == "Student" ? updatedSettings.entlastungsstollenTicketTypeId20 : updatedSettings.entlastungsstollenTicketTypeId50;
            let gubristtunnelTicketTypeId = selectedCategory == "Student" ? updatedSettings.gubristtunnelTicketTypeId20 : updatedSettings.gubristtunnelTicketTypeId50;

            let tagungsbands = 0;

            updatedOrder.tickets.map((ticket) => {
                if (ticket.ticketTypeId == updatedSettings.tagungsbandTicketTypeId) {
                    tagungsbands++;
                }
            })

            const updatedTicketTypeInfo = await Promise.all(updatedOrder?.tickets?.map(async (ticket) => {
                const ticketTypeInfo = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                return ticketTypeInfo[languageId] ?? ticketTypeInfo[0];
            }))

            let updatedSelectedPackage = selectedCategory;

            if (selectedCategory == "Paket STSym") {
                updatedSelectedPackage = "STSym";
                updatedOrder.tickets.map((ticket) => {
                    if (ticket.ticketTypeId == updatedSettings.paketSTSymTicketTypeId) {
                        updatedSelectedPackage = selectedCategory;
                    }
                })
            }

            if (updatedSelectedPackage == "FGU Kollektivmitglied") {
                if (languageId == 3) {
                    updatedSelectedPackage = "STS collective member"
                } else if (languageId == 2) {
                    updatedSelectedPackage = "Membre collectif du GTS"
                } else {
                    updatedSelectedPackage = "FGU Kollektivmitglied"
                }
            } else if (updatedSelectedPackage == "FGU Einzelmitglied") {
                if (languageId == 3) {
                    updatedSelectedPackage = "STS individual member"
                } else if (languageId == 2) {
                    updatedSelectedPackage = "Membre individuel du GTS"
                } else {
                    updatedSelectedPackage = "FGU Einzelmitglied"
                }
            } else if (updatedSelectedPackage == "Paket STSym") {
                if (languageId == 3) {
                    updatedSelectedPackage = "Package STSym"
                } else if (languageId == 2) {
                    updatedSelectedPackage = "Package STSym"
                } else {
                    updatedSelectedPackage = "Paket STSym"
                }
            } else if (updatedSelectedPackage == "STSym") {
                if (languageId == 3) {
                    updatedSelectedPackage = "STSym"
                } else if (languageId == 2) {
                    updatedSelectedPackage = "STSym"
                } else {
                    updatedSelectedPackage = "STSym"
                }
            } else if (updatedSelectedPackage == "Nichtmitglied") {
                if (languageId == 3) {
                    updatedSelectedPackage = "Non-member"
                } else if (languageId == 2) {
                    updatedSelectedPackage = "Non-membre"
                } else {
                    updatedSelectedPackage = "Nichtmitglied"
                }
            } else if (updatedSelectedPackage == "Student") {
                if (languageId == 3) {
                    updatedSelectedPackage = "Student"
                } else if (languageId == 2) {
                    updatedSelectedPackage = "Étudiant"
                } else {
                    updatedSelectedPackage = "Student"
                }
            }

            setSelectedPackage(updatedSelectedPackage);
            setPayment({ ...payment, paymentMethodId: updatedOrder.paymentMethodId, deliveryMethodId: updatedOrder.deliveryMethodId });

            setOrder(updatedOrder);

            //sort tickets
            //1. paket
            //2. kolloquium
            //2. guest
            //4. congress
            //5. dinner
            //6. exkursion

            setTicketTypeInfo(updatedTicketTypeInfo);

            setSettings({
                stsTicketTypeId: updatedSettings.stsTicketTypeId,
                paketSTSymTicketTypeId: updatedSettings.paketSTSymTicketTypeId,
                congressTicketTypeIds: updatedSettings.congressTicketTypeIds,
                kolloquiumTicketTypeIds: updatedSettings.kolloquiumTicketTypeIds,
                dinnerTicketTypeIds: updatedSettings.dinnerTicketTypeIds,
                exkursionTicketTypeIds: updatedSettings.exkursionTicketTypeIds,
                tagungsbandTicketTypeId: updatedSettings.tagungsbandTicketTypeId,
                guestAndSponsorTicketTypeId: updatedSettings.guestAndSponsorTicketTypeId,
                exkursionSubTicketTypeId: exkursionSubTicketTypeId,
                gotthardTicketTypeId: gotthardTicketTypeId,
                entlastungsstollenTicketTypeId: entlastungsstollenTicketTypeId,
                gubristtunnelTicketTypeId: gubristtunnelTicketTypeId,
                exkursionGotthardTicketTypeId: updatedSettings.gotthardTicketTypeIdFree,
                exkursionEntlastungsstollenTicketTypeId: updatedSettings.entlastungsstollenTicketTypeIdFree,
                exkursionGubristtunnelTicketTypeId: updatedSettings.gubristtunnelTicketTypeIdFree,
                stsymKolloquiumTicketTypeId: updatedSettings.stsymKolloquiumTicketTypeId,
                stsymCongressTicketTypeId: updatedSettings.stsymCongressTicketTypeId,
                stsymDinnerTicketTypeId: updatedSettings.stsymDinnerTicketTypeId,
                stsymExkursionTicketTypeId: updatedSettings.stsymExkursionTicketTypeId
            });

            setTagungsbands(tagungsbands);
        } catch (error) {
            console.error(error);
        }
    }

    const setupSTSTicket = async () => {
        try {

            const theOrder = await getOrderByOrderId(orderId);

            if (!(theOrder?.tickets?.some(t => t.ticketTypeId == settings.stsTicketTypeId))) {

                const order = await addTicketsToBasket([{ ticketTypeId: settings.stsTicketTypeId, quantity: 1 }]);

                await Promise.all(order?.tickets?.map(async (ticket) => {
                    const ticketTypeInfo = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeName = ticketTypeInfo[languageId]?.name ?? ticketTypeInfo[0]?.name;

                    let address = { ...ticket };

                    address.option12 = ticketTypeName;

                    if (selectedCategory == "Paket STSym") {
                        let containsTicket = order?.tickets?.some(t => t.ticketTypeId == settings.paketSTSymTicketTypeId);

                        if (!containsTicket) {
                            if (ticketTypeName?.includes("Paket STSym")) {
                                address.option12 = ticketTypeName?.replace("Paket STSym", "STSym");
                            }

                            if (ticketTypeName?.includes("Package STSym")) {
                                address.option12 = ticketTypeName?.replace("Package STSym", "STSym");
                            }

                            if (ticketTypeName?.includes("FGU Einzelmitglied")) {
                                address.option12 = ticketTypeName?.replace("FGU Einzelmitglied", "STSym");
                            }

                            if (ticketTypeName?.includes("Membre individuel")) {
                                address.option12 = ticketTypeName?.replace("Membre individuel", "STSym");
                            }

                            if (ticketTypeName?.includes("STS individual member")) {
                                address.option12 = ticketTypeName?.replace("STS individual member", "STSym");
                            }
                        }

                    }

                    if (ticket.ticketTypeId == settings.guestAndSponsorTicketTypeId) {
                        address.customValue1 = `${selectedPackage} `;
                    }

                    if (ticket.ticketTypeId == settings.exkursionSubTicketTypeId || settings.exkursionTicketTypeIds.includes(ticket.id)) {
                        if (!ticketTypeName?.includes("Student") && !ticketTypeName?.includes("Étudiant")) {
                            address.customValue1 = `${selectedPackage} `;
                        }
                    }

                    if (settings.exkursionGotthardTicketTypeId == ticket.ticketTypeId) {
                        address.customValue1 = `${selectedPackage} `;
                        address.option12 = `${resources?.Exkursion} 1`;
                    }

                    if (settings.gotthardTicketTypeId == ticket.ticketTypeId) {
                        address.customValue1 = `${selectedPackage} `;
                        address.option12 = `${resources?.Exkursion} 1 (${resources?.ZumCongress})`;
                    }

                    if (settings.exkursionEntlastungsstollenTicketTypeId == ticket.ticketTypeId) {
                        address.customValue1 = `${selectedPackage} `;
                        address.option12 = `${resources?.Exkursion} 2`;
                    }


                    if (settings.entlastungsstollenTicketTypeId == ticket.ticketTypeId) {
                        address.customValue1 = `${selectedPackage} `;
                        address.option12 = `${resources?.Exkursion} 2 (${resources?.ZumCongress})`;
                    }

                    if (settings.exkursionGubristtunnelTicketTypeId == ticket.ticketTypeId) {
                        address.customValue1 = `${selectedPackage} `;
                        address.option12 = `${resources?.Exkursion} 3`;
                    }


                    if (settings.gubristtunnelTicketTypeId == ticket.ticketTypeId) {
                        address.customValue1 = `${selectedPackage} `;
                        address.option12 = `${resources?.Exkursion} 3 (${resources?.ZumCongress})`;
                    }

                    if (settings.exkursionTicketTypeIds.includes(ticket.ticketTypeId) || settings.stsymExkursionTicketTypeId == ticket.ticketTypeId) {
                        address.customValue1 = "";
                        address.option12 = "";
                    }

                    await updateAddressOfTicket(ticket.id, address);
                }))

                const updatedOrder = await getOrderByOrderId(orderId);
                const addressOfOrder = await getAddressOfOrder(orderId);

                let updatedAddress = { ...addressOfOrder };
                updatedAddress.firstName = addressOfOrder.firstName;
                updatedAddress.name = addressOfOrder.name;

                updatedAddress.option9 = selectedCategory;

                await Promise.all(
                    updatedOrder.tickets.map(async (ticket) => {

                        if (settings.kolloquiumTicketTypeIds.includes(ticket.ticketTypeId) || settings.stsymKolloquiumTicketTypeId == ticket.ticketTypeId) {
                            updatedAddress.option1 = "K";
                        } else if (settings.guestAndSponsorTicketTypeId === ticket.ticketTypeId) {
                            updatedAddress.option2 = "G";
                        } else if (settings.congressTicketTypeIds.includes(ticket.ticketTypeId)) {
                            updatedAddress.option3 = "C";
                        } else if (settings.dinnerTicketTypeIds.includes(ticket.ticketTypeId) || settings.stsymDinnerTicketTypeId == ticket.ticketTypeId) {
                            updatedAddress.option4 = "F";
                        }

                        const ticketTypeInfo = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                        const ticketTypeName = ticketTypeInfo[languageId]?.name ?? ticketTypeInfo[0]?.name;;

                        if (ticket.ticketTypeId == settings.gotthardTicketTypeId || ticket.ticketTypeId == settings.exkursionGotthardTicketTypeId) {
                            updatedAddress.option5 = "1";
                            updatedAddress.option8 = ticketTypeName;
                        }

                        if (ticket.ticketTypeId == settings.entlastungsstollenTicketTypeId || ticket.ticketTypeId == settings.exkursionEntlastungsstollenTicketTypeId) {
                            updatedAddress.option5 = "2";
                            updatedAddress.option8 = ticketTypeName;
                        }

                        if (ticket.ticketTypeId == settings.gubristtunnelTicketTypeId || ticket.ticketTypeId == settings.exkursionGubristtunnelTicketTypeId) {
                            updatedAddress.option5 = "3";
                            updatedAddress.option8 = ticketTypeName;
                        }
                    })
                )

                let publication = sessionStorage.getItem("agbPublication");
                if (publication == true || publication == "true") {
                    updatedAddress.option6 = resources?.AGB2 + " - JA"
                }

                let stsTicket = updatedOrder?.tickets?.find(t => t.ticketTypeId == settings.stsTicketTypeId);

                const ticketTypeInfo = await getTicketTypeInfosByTicketTypeId(stsTicket.ticketTypeId);
                const ticketTypeName = ticketTypeInfo[languageId]?.name ?? ticketTypeInfo[0]?.name;;

                updatedAddress.option12 = ticketTypeName;

                await updateAddressOfTicket(stsTicket.id, updatedAddress);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const removePromotionCode = async (promotion) => {
        try {
            const response = axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const addTicketsToBasket = async (addTicketTypes) => {
        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { ticketsToAdd: addTicketTypes });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const changeDeliveryType = async (deliveryMethodId) => {
        await axios.put(
            `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryMethodId}`)
            .then(() => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const changePaymentType = (paymentMethodId) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentMethodId}`)
            .then((res) => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form className='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const onBack = async () => {
        const promotioncode = sessionStorage.getItem("addedPromotion");

        if (promotioncode?.length > 0) {
            await removePromotionCode(promotioncode);
        }

        navigate(-1)
    }

    const onSubmit = async () => {

        const stsTicket = order.tickets.find(ticket => ticket.ticketTypeId == settings.stsTicketTypeId);

        if (stsTicket) {
            await removeTicketFromOrder([stsTicket.id]);
            await setupSTSTicket();
        } else {
            await setupSTSTicket();
        }


        if (order?.totalPrice > 0) {
            let dataTransProviders = [1, 7, 8, 9, 10, 14];

            let datatransProvider = dataTransProviders.findIndex((d) => d === payment.paymentMethodId) !== -1

            if (datatransProvider) {
                startDatatrans();
            } else if (payment.paymentMethodId == 2) {
                await confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            }
        } else {
            changePaymentTypeToFree();
        }
    }

    const updateAddressOfTicket = async (ticketId, body) => {
        try {
            const response = await axios.put(`${baseUrl}/Ticket/${ticketId}/Address`, body);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const deleteTicketsFromBasket = async (ticketIds) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: { "TicketsToRemove": ticketIds } });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {
                await confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            });
    }

    const removeTicketFromOrder = async (ticketIds) => {
        const order = await deleteTicketsFromBasket(ticketIds);
        loadOrder();
    }

    const mapSelectedTicketTypes = () => {
        const paketSTSymTicketType = order?.tickets?.find(t => t.ticketTypeId == settings?.paketSTSymTicketTypeId);
        const kolloquiumTicketType = order?.tickets?.find(t => settings?.kolloquiumTicketTypeIds?.includes(t.ticketTypeId) || settings?.stsymKolloquiumTicketTypeId == t.ticketTypeId);
        const guestAndSponsorTicketType = order?.tickets?.find(t => t.ticketTypeId == settings?.guestAndSponsorTicketTypeId);
        const congressTicketType = order?.tickets?.find(t => settings?.congressTicketTypeIds?.includes(t.ticketTypeId));
        const dinnerTicketType = order?.tickets?.find(t => settings?.dinnerTicketTypeIds?.includes(t.ticketTypeId) || settings?.stsymDinnerTicketTypeId == t.ticketTypeId);
        const exkursionTicketType = order?.tickets?.find(t => settings?.exkursionTicketTypeIds?.includes(t.ticketTypeId) || settings?.stsymExkursionTicketTypeId == t.ticketTypeId);

        const exkursionSubTicketType = order?.tickets?.find(t =>
            t.ticketTypeId == settings?.gotthardTicketTypeId
            || t.ticketTypeId == settings?.entlastungsstollenTicketTypeId
            || t.ticketTypeId == settings?.gubristtunnelTicketTypeId
            || t.ticketTypeId == settings?.exkursionGotthardTicketTypeId
            || t.ticketTypeId == settings?.exkursionEntlastungsstollenTicketTypeId
            || t.ticketTypeId == settings?.exkursionGubristtunnelTicketTypeId);

        const tagungsbandTicketTypes = order?.tickets?.filter(t => t.ticketTypeId == settings?.tagungsbandTicketTypeId);

        let tagungsbandTicketTypesPrice = 0;
        tagungsbandTicketTypes?.map((ttt) => { tagungsbandTicketTypesPrice = tagungsbandTicketTypesPrice + ttt?.price });

        return (
            <>
                {paketSTSymTicketType && <li className='list-group-item'>
                    <div className="row">
                        <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder(order?.tickets?.map(t => { if (t.ticketTypeId != settings?.tagungsbandTicketTypeId) return t.id }).filter(t => t > 0))}></i></div>
                        <div className="col-7 fw-semibold">{resources?.Workshop1Summary}</div>
                        <div className="col-4 text-end">{order.currency} {paketSTSymTicketType.price?.toFixed(2)}</div>
                    </div>
                </li>}
                {kolloquiumTicketType && <li className='list-group-item'>
                    <div className="row">
                        <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([kolloquiumTicketType.id])}></i></div>
                        <div className="col-7 fw-semibold">{resources?.Workshop2Summary}</div>
                        <div className="col-4 text-end">{order.currency} {kolloquiumTicketType.price?.toFixed(2)}</div>
                    </div>
                </li>}
                {guestAndSponsorTicketType && <li className='list-group-item'>
                    <div className="row">
                        <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([guestAndSponsorTicketType.id])}></i></div>
                        <div className="col-7 fw-semibold">{resources?.Workshop3Summary}</div>
                        <div className="col-4 text-end">{order.currency} {guestAndSponsorTicketType.price?.toFixed(2)}</div>
                    </div>
                </li>}
                {congressTicketType && <li className='list-group-item'>
                    <div className="row">
                        <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([congressTicketType.id, exkursionSubTicketType?.id ?? 0])}></i></div>
                        <div className="col-7 fw-semibold">{resources?.Workshop4Summary}</div>
                        <div className="col-4 text-end">{order.currency} {congressTicketType.price?.toFixed(2)}</div>
                        {exkursionSubTicketType && <div className='col-12'>
                            <div className='row'>
                                <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([exkursionSubTicketType.id, congressTicketType.id])}></i></div>
                                <div className="col-7">{ticketTypeInfo.find(t => t?.ticketTypeId == exkursionSubTicketType?.ticketTypeId).name}</div>
                                <div className="col-4 text-end">{order.currency} {exkursionSubTicketType.price?.toFixed(2)}</div>
                            </div>
                        </div>}
                    </div>
                </li>}
                {dinnerTicketType && <li className='list-group-item'>
                    <div className="row">
                        <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([dinnerTicketType.id])}></i></div>
                        <div className="col-7 fw-semibold">{resources?.Workshop5Summary}</div>
                        <div className="col-4 text-end">{order.currency} {dinnerTicketType.price?.toFixed(2)}</div>
                    </div>
                </li>}
                {exkursionTicketType && <li className='list-group-item'>
                    <div className="row">
                        <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([exkursionTicketType.id, exkursionSubTicketType?.id ?? 0])}></i></div>
                        <div className="col-7 fw-semibold">{resources?.Workshop6Summary}</div>
                        <div className="col-4 text-end">{order.currency} {exkursionTicketType.price?.toFixed(2)}</div>
                        {exkursionSubTicketType && <div className='col-12'>
                            <div className='row'>
                                <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([exkursionSubTicketType.id, exkursionTicketType.id])}></i></div>
                                <div className="col-7">{ticketTypeInfo.find(t => t?.ticketTypeId == exkursionSubTicketType?.ticketTypeId).name}</div>
                                <div className="col-4 text-end"></div>
                            </div>
                        </div>}
                    </div>
                </li>}
                {tagungsbandTicketTypes?.length > 0 && <li className='list-group-item'>
                    <div className="row">
                        <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder(tagungsbandTicketTypes.map(ttt => { return ttt.id }))}></i></div>
                        <div className="col-7 fw-semibold">{resources?.Workshop7Summary} ({tagungsbands})</div>
                        <div className="col-4 text-end">{order.currency} {tagungsbandTicketTypesPrice?.toFixed(2)}</div>
                        {tagungsbandTicketTypes?.map((ticket) => {
                            if (ticket.ticketTypeId == settings.tagungsbandTicketTypeId) {

                                return (
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-1 text-danger'><i class="bi bi-x-circle" onClick={() => removeTicketFromOrder([ticket?.id])}></i></div>
                                            <div className="col-7">{resources?.Workshop7Sub1}</div>
                                            <div className="col-4 text-end">{order.currency} {ticket?.price?.toFixed(2)}</div>
                                        </div>
                                    </div>
                                )

                            }
                        })}

                    </div>
                </li>}
                {order.deliverPaymentFee > 0 && <li className='list-group-item'>
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-7 fw-semibold">{resources?.DeliveryPaymentCost}</div>
                        <div className="col-4 text-end">{order.currency} {order.deliverPaymentFee?.toFixed(2)}</div>
                    </div>
                </li>}
            </>
        )
    }

    let disabled = !(order?.tickets?.length > 0);

    return (
        <div className='container page-container'>
            <div id="datatrans-form-placeholder"></div>
            <Header />
            <div className='mt-3'>
                <div className='text-center'>
                    <p className='fs-4 mb-4 text-primary fw-semibold'>
                        <span className='underline'>{resources.DeliveryPaymentOptions}</span>
                    </p>
                </div>
                <div className='mb-4'>
                    <p className='fs-5 fw-semibold'>{resources.DeliveryOption}</p>
                    {deliveryTypes.map((deliveryType) => (<div className="form-check mb-3">
                        <input
                            className="form-check-input me-2"
                            type="radio"
                            name="flexRadioDefault"
                            checked={payment.deliveryMethodId === deliveryType.id}
                            onClick={() => changeDeliveryType(deliveryType.id)}
                            id={deliveryType.name}
                        />
                        <label
                            className="form-check-label fs-6"
                            htmlFor={deliveryType.name}
                        >
                            {deliveryType.name}
                            <p className="pt-1 m-0 text-primary">
                                {deliveryType.description}
                            </p>
                        </label>
                    </div>))}
                </div>
                {order?.totalPrice > 0 && <div className='mb-4'>
                    <p className='fs-5 fw-semibold'>{resources.PaymentOption}</p>
                    {paymentTypes.map((paymentType) => (<div className="form-check mb-3">
                        <input
                            className="form-check-input me-2"
                            type="radio"
                            name="flexRadioDefault1"
                            checked={payment.paymentMethodId === paymentType.id}
                            onClick={() => changePaymentType(paymentType.id)}
                            id={paymentType.name}
                        />
                        <label
                            className="form-check-label fs-6"
                            htmlFor={paymentType.name}
                        >
                            {paymentType.name}
                            <p className="pt-1 m-0 text-primary">
                                {paymentType.description}
                            </p>
                        </label>
                    </div>))}
                </div>}
                <div className='mb-5'>
                    <p className='fs-5 fw-semibold'>{resources.Summary}</p>
                    <div>
                        <ul className='list-group'>
                            <li className='list-group-item'>
                                <div className="row">
                                    <div className="col-12 fw-bold">{selectedPackage}</div>
                                </div>
                            </li>
                            {mapSelectedTicketTypes()}
                            <li className='list-group-item'>
                                <div className="row">
                                    <div className="col-8 fw-bold">{resources.Total}</div>
                                    <div className="col-4 text-end fw-bold">{order.currency} {order.totalPrice?.toFixed(2)}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-8">
                        <button className='btn button text-start' onClick={onBack}>{resources.Back}</button>
                    </div>
                    <div className="col-4 text-end">
                        <button className='btn button' disabled={disabled} onClick={onSubmit}>{resources.Complete}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Checkout